import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import ImageBL from "./ImageBL";
import { Card, Box, Typography, Button } from "@mui/material";
import styled from "styled-components";
import { getPriceObject } from "../src/price";
import { makeStyles } from "@mui/styles";
import { ModalMB } from "../mobx/modal";
import LegoButton from "./LegoButton";
import { ISet } from "../mobx/globalStore";
import Link from "next/link";

const Item = styled(Card)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  position: relative;
  /* margin: 1rem; */
  button {
    font-size: 1rem;
  }
  padding: 1rem;
  border-bottom: 1px solid grey;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const ButtonComponent = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  > * {
    margin-bottom: 1rem;
  }
`;

const useStyles = makeStyles({
  cheaper: {
    color: "green",
    borderColor: "green",
  },
  expensive: {
    color: "orange",
    borderColor: "orange",
  },
});

interface IItemSet {
  set: ISet;
  modal: ModalMB;
}

const ItemSet = ({ set, modal }: IItemSet) => {
  const classes = useStyles();

  const { id_lego, name, brand, price, rent_15, rent_30 } = set;
  const { price15, price30 } = getPriceObject(price);

  const handleBorrow = (duration: number, price: number, id: number) => {
    window.gtag("event", "borrow_interested", {
      id: id,
      price: price,
    });
    modal.setOpen(true, duration, price, id);
  };

  return (
    <Item key={id_lego} elevation={3}>
      <LegoButton id_lego={id_lego} />
      <ImageBL id_lego={id_lego} />
      <Box marginLeft="2rem" display="flex" flexDirection="column" flex={1}>
        <Link href={`/wypozyczalnia/${id_lego}-${name}`}>
          <a>
            <Typography variant="h5">
              {id_lego} - {name}
            </Typography>
            <Typography color="GrayText">{brand}</Typography>
          </a>
        </Link>
        <ButtonComponent>
          <Button
            className={classes.cheaper}
            onClick={(e) => handleBorrow(15, price, id_lego)}
            variant="outlined"
          >
            Wypożycz na 15 dni za {rent_15} zł
          </Button>
          <Button
            className={classes.expensive}
            onClick={(e) => handleBorrow(30, price, id_lego)}
            variant="outlined"
          >
            Wypożycz na 30 dni za {rent_30} zł
          </Button>
        </ButtonComponent>
      </Box>
    </Item>
  );
};

export default observer(ItemSet);
