import React, { useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Icon, Radio, RadioGroup, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { sendBorrow, sendMail } from '../data/mail';
import { ModalMB } from '../mobx/modal';
import { getPriceObject } from '../src/price';
import * as yup from 'yup'
import { REQUIRED_FIELD } from '../src/global';
import ReCAPTCHA from 'react-google-recaptcha';

const ConfirmBorrow = ({ modal }: { modal: ModalMB }) => {
   const [sending, setSending] = useState(false)
   const recaptchaRef = React.createRef<ReCAPTCHA>();
   const { price15, price30 } = getPriceObject(modal.price)

   return (
      <Dialog
         open={modal.open}
         maxWidth="sm"
         fullWidth
         style={{
            zIndex: 999999
         }}
      >
         <DialogTitle>Formularz wypożyczenia</DialogTitle>
         <DialogContent>

            <Formik
               initialValues={{
                  name: "",
                  note: "",
                  email: "",
                  phone: "",
                  duration: modal.duration
               }}
               onSubmit={async (values) => {
                  if (!recaptchaRef.current?.getValue()) return alert("Potwierdź, że nie jesteś robotem!")
                  recaptchaRef.current.reset()
                  setSending(true)
                  const result = await sendBorrow({ ...values, id: modal.id, price: modal.price })
                  setSending(false)
                  if ("send" in result && result.send)
                     alert("Wiadomość została wysłana. Poczekaj aż nasz pracownik skontaktuję się z Tobą ;)")
                  modal.close()
               }}

               validationSchema={yup.object({
                  name: yup.string()
                     .required(REQUIRED_FIELD)
                     .min(3, "Imię i nazwisko powinna posiadać minimum 3 znaki"),
                  email: yup.string()
                     .required(REQUIRED_FIELD)
                     .email("Nie poprawny adres"),
                  note: yup.string()
                     .required(REQUIRED_FIELD)
                     .min(10, "Wiadomość powinna posiadać minimum 10 znaków"),
                  phone: yup.string()
                     .min(9, "Numer telefonu powinnien posiadać minimum 9 znaków"),

               })}
            >
               {({ values, handleChange, errors, touched }) => {
                  return (
                     <Form style={{
                        display: "flex",
                        flexDirection: "column"
                     }}>
                        <TextField
                           label="Imię i nazwisko"
                           id="name"
                           name="name"
                           variant="standard"
                           value={values.name}
                           onChange={handleChange}
                           error={touched.name && Boolean(errors.name)}
                           helperText={touched.name && errors.name}
                        />
                        <TextField
                           label="Numer telefonu"
                           id="phone"
                           name="phone"
                           variant="standard"
                           value={values.phone}
                           onChange={handleChange}
                           error={touched.phone && Boolean(errors.phone)}
                           helperText={touched.phone && errors.phone}
                        />
                        <TextField
                           label="Email"
                           id="email"
                           name="email"
                           variant="standard"
                           value={values.email}
                           onChange={handleChange}
                           error={touched.email && Boolean(errors.email)}
                           helperText={touched.email && errors.email}
                        />
                        <TextField
                           label="Uwagi"
                           id="note"
                           name="note"
                           variant="standard"
                           value={values.note}
                           onChange={handleChange}
                           multiline
                           rows={2}
                           error={touched.note && Boolean(errors.note)}
                           helperText={touched.note && errors.note}
                        />
                        <RadioGroup
                           value={values.duration}
                           name="duration"
                           onChange={handleChange}
                        >
                           <FormControlLabel value="15" control={<Radio />} label={`15 dni - ${price15} zł`} />
                           <FormControlLabel value="30" control={<Radio />} label={`30 dni - ${price30} zł`} />
                        </RadioGroup>
                        <ReCAPTCHA
                           ref={recaptchaRef}
                           sitekey="6Lf5-IkdAAAAAKGcx9d4927xYgSUYBKkmfylSH6p"
                        />
                        <Button type="submit" variant="outlined"
                           endIcon={sending ? <Icon>hourglass_top</Icon> : null}
                        >Wyślij</Button>
                     </Form>
                  )
               }}
            </Formik>
         </DialogContent>
         <DialogActions>
            <Button onClick={() => modal.close()}>Anuluj</Button>
            {/* <Button>Wyślij</Button> */}
         </DialogActions>
      </Dialog>
   )
}

export default observer(ConfirmBorrow);