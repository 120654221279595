import React from "react";
import { observer } from "mobx-react-lite";
import Image from "next/image";
import { Box } from "@mui/material";

const LegoButton = ({
  id_lego,
  width = 50,
  height = 50,
}: {
  id_lego: number;
  width?: number;
  height?: number;
}) => {
  return (
    <Box position="absolute" className="pointer" right="1rem" top="1rem" zIndex={2}>
      <a
        onClick={(e) => {
          window.gtag("event", `LEGO_BUTTON_${id_lego}`, {});
        }}
        href={`https://click.linksynergy.com/deeplink?id=dZ8Qe15FIE4&mid=24340&u1=wypozyczalnia&murl=https://www.lego.com/pl-pl/product/${id_lego}`}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          width={width}
          height={height}
          src={"/img/logo_lego.svg"}
          // layout='fill'
          objectFit="contain"
        />
      </a>
    </Box>
  );
};

export default observer(LegoButton);
