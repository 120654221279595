import { makeAutoObservable } from "mobx";

export class ModalMB {
   constructor() {
      makeAutoObservable(this)
   }

   open: boolean = false
   duration: number = 0
   price: number = 0
   id: number = 0

   setOpen(open: boolean, duration: number, price: number, id: number) {
      this.open = open
      this.duration = duration
      this.price = price
      this.id = id
   }
   
   close() {
      this.open = false
      this.duration = 0
      this.price = 0
      this.id = 0
   }
}
