import React from 'react'
import Image, { ImageLoader, ImageLoaderProps } from 'next/image'
import { observer } from 'mobx-react-lite'

interface IImageBL {
   id_lego: number
}

const ImageBL = ({ id_lego }: IImageBL) => {
   const baseUrl = "https://img.bricklink.com/ItemImage"
   const srcUrl = baseUrl + `/ON/0/${id_lego}-1.png`
   // const srcUrl =  '/ItemImage/ON/0/${id}-1.png'
   
   const emptyAlt = `Zdjęcie zestawu ${id_lego}`
   const destAlt = emptyAlt
   
   const myLoader = ({src} :ImageLoaderProps) => {
      return `https://img.bricklink.com/ItemImage/ON/0/${src}-1.png`
   }

   return (
      <Image
         alt={destAlt}
         // style={style}
         src={id_lego.toString()}
         loader={myLoader}
         objectFit="contain"
         width={200}
         height={200}
         onError={(e) => {
            // e.currentTarget.src = `https://images.brickset.com/sets/small/${id_lego}-1.jpg`
         }}
      />
   )
}

export default observer(ImageBL)